import React from 'react';
import { useNavigate } from 'react-router-dom';

const SubscriptionOverlay = ({ trialEndDate }) => {
  const navigate = useNavigate();
  const daysUntilDeletion = Math.ceil((trialEndDate * 1000 + 30 * 24 * 60 * 60 * 1000 - Date.now()) / (1000 * 60 * 60 * 24));

  return (
    <div className="fixed inset-0 z-50">
      {/* Semi-transparent backdrop with blur */}
      <div className="absolute inset-0 bg-white/50 backdrop-blur-sm" />

      {/* Content */}
      <div className="absolute inset-0 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full space-y-6">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900 mb-2">Trial Period Ended</h2>
            <p className="text-gray-600 mb-4">
              Your free trial has expired. Subscribe now to continue using Psyntel and maintain access to all your data.
            </p>

            {/* Warning about account deletion */}
            <div className="bg-red-50 border border-red-200 rounded-md p-4 mb-6">
              <p className="text-sm text-red-600">
                Important: Your account and all associated data will be permanently deleted in {daysUntilDeletion} days.
              </p>
            </div>

            {/* Feature reminders */}
            <div className="text-left bg-blue-50 rounded-md p-4 mb-6">
              <p className="font-medium text-blue-900 mb-2">Don't lose access to:</p>
              <ul className="text-sm text-blue-800 space-y-1">
                <li>• All your client records and notes</li>
                <li>• Generated insights and analyses</li>
                <li>• Chat histories and questionnaires</li>
                <li>• Custom assessments and reports</li>
              </ul>
            </div>

            <button
              onClick={() => window.location.href = 'https://buy.stripe.com/7sI6qF4ZWbKigbCfYY'}
              className="w-full bg-blue-600 text-white rounded-lg px-4 py-3 hover:bg-blue-700 transition-colors font-medium"
            >
              Subscribe Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionOverlay;
